<div
  class="abs top-0 left-0 s-full @max-1163px-overflow-x-clip"
  style:height="100vh"
>
  <svg
    class="abs hidden md-block"
    width="1043"
    height="532"
    viewBox="0 0 1043 532"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_f_1_1182)">
      <path
        d="M710 -683.805L946.249 -1037L758.497 -655.805L308.497 123.618L96.2487 435.243L260 95.6176L710 -683.805Z"
        fill="url(#paint0_linear_1_1182)"
        fill-opacity="0.12"
      />
    </g>
    <defs>
      <filter
        id="filter0_f_1_1182"
        x="0.248718"
        y="-1133"
        width="1042"
        height="1664.24"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="48"
          result="effect1_foregroundBlur_1_1182"
        />
      </filter>
      <linearGradient
        id="paint0_linear_1_1182"
        x1="946.249"
        y1="-1037"
        x2="96.2487"
        y2="435.243"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="white" stop-opacity="0.54" />
      </linearGradient>
    </defs>
  </svg>

  <svg
    class="hidden md-block abs right--80px"
    width="1091"
    height="1015"
    viewBox="0 0 1091 1015"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_f_1_1181)">
      <path
        d="M733.779 -224.797L970.028 -577.992L782.277 -196.797L332.277 582.625L120.028 894.251L283.779 554.625L733.779 -224.797Z"
        fill="url(#paint0_linear_1_1181)"
        fill-opacity="0.16"
      />
    </g>
    <defs>
      <filter
        id="filter0_f_1_1181"
        x="0.0280151"
        y="-697.992"
        width="1090"
        height="1712.24"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="60"
          result="effect1_foregroundBlur_1_1181"
        />
      </filter>
      <linearGradient
        id="paint0_linear_1_1181"
        x1="970.028"
        y1="-577.992"
        x2="120.028"
        y2="894.251"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.458645" stop-color="white" stop-opacity="0.54" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
    </defs>
  </svg>

  <svg
    class="abs right--120px"
    width="643"
    height="766"
    viewBox="0 0 643 766"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_f_1_1183)">
      <path
        d="M710 -449.805L946.249 -803L758.497 -421.805L308.497 357.618L96.2487 669.243L260 329.618L710 -449.805Z"
        fill="url(#paint0_linear_1_1183)"
        fill-opacity="0.2"
      />
    </g>
    <defs>
      <filter
        id="filter0_f_1_1183"
        x="0.248718"
        y="-899"
        width="1042"
        height="1664.24"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="48"
          result="effect1_foregroundBlur_1_1183"
        />
      </filter>
      <linearGradient
        id="paint0_linear_1_1183"
        x1="470"
        y1="21"
        x2="248"
        y2="448.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </linearGradient>
    </defs>
  </svg>
</div>
