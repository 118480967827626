<script lang="ts">
  import "@unocss/reset/tailwind.css"; // https://github.com/unocss/unocss#style-resetting
  import "uno.css"; // https://github.com/unocss/unocss/tree/main/packages/vite#installation
  import "../app.css";
  import TheNav from "./components/nav/TheNav.svelte";
</script>

<svelte:head>
  <meta name="theme-color" content="#0a0300" />
  <title>CoachCamel - Your AI Gaming Coach!</title>
  <meta property="og:url" content="https://coachcamel.com/" />
  <meta property="og:image" content="https://coachcamel.com/og.jpg" />
  <meta property="twitter:card" content="summary_large_image" />
  <meta property="twitter:creator" content="@HeyCoachCamel" />
  <meta property="twitter:site" content="@HeyCoachCamel" />
  <meta property="twitter:title" content="CoachCamel - Your AI Gaming Coach!" />
  <meta property="twitter:url" content="https://coachcamel.com/" />
  <meta property="twitter:image" content="https://coachcamel.com/og.jpg" />
</svelte:head>

<TheNav />
<slot />
