<script lang="ts">
  import logoname from "$lib/assets/logo/logo-name.webp";
  import logo from "$lib/assets/logo/logo.svg";
  import name from "$lib/assets/logo/name.webp";

  import Blobs from "./Blobs.svelte";

  function updateScrollPadding(node: HTMLElement) {
    document.documentElement.style.setProperty(
      "--scroll-padding-top",
      `${node.offsetHeight}px`
    );
  }
</script>

<div class="abs top-0 left-0 w-full z-41 pointer-events-none">
  <div class="relative max-w-6xl mx-a md-py-6">
    <Blobs />
  </div>
</div>

<header use:updateScrollPadding class="sticky z-40 top-0 md-px-6">
  <div class="relative max-w-6xl mx-a md-py-6">
    <nav
      class="p-5 flex-s-between b-b bg-neutral-brown-900 b-neutral-brown-700 md-(b rd-xl px-8)"
    >
      <!-- LOGO -->
      <a class="flex-s-start gap-2 md-hidden" href="/">
        <div class="h-30px">
          <img class="h-full" src={logo} alt="" />
        </div>
        <div class="h-15px">
          <img class="h-full" src={name} alt="" />
        </div>
      </a>
      <a class="hidden md-block" href="/">
        <div class="h-30px">
          <img class="h-full" src={logoname} alt="" />
        </div>
      </a>

      <div class="hidden text-sm gap-10 md-flex justify-between">
        <div>
          <a target="_blank" href="https://discord.gg/HfzRK88RQP">Discord</a>
        </div>
        <div><a href="/#faq">FAQ</a></div>
      </div>

      <!-- CALL-TO-ACTION -->
      <a
        target="_blank"
        href="https://download.overwolf.com/install/Download?Name=CoachCamel&ExtensionId=pnefddejdcadgnbjnmlhijbmnhgjobkcjgpgaaei&Channel=web_dl_btn"
        class="inline-block font-(medium general) text-sm rd-md btn"
        download
        ><span class="text-radial">Download for Windows</span>
      </a>
      <!-- <a
        target="_blank"
        href="https://discord.gg/29YH7hTQXk"
        class="inline-block font-(medium general) text-sm rd-md btn"
      >
        <p class="text-radial flex flex-col items-center">
          <span>Join for Updates</span>

          <span class="text-xs">Undergoing Maintenance</span>
        </p>
      </a> -->
    </nav>
  </div>
</header>

<style>
  nav {
    box-shadow: 0px -3px 35px 0px rgba(255, 255, 255, 0.05) inset;
  }

  .btn {
    padding: 8px 14px;
    background: linear-gradient(
        180deg,
        rgba(24, 24, 24, 0.16) 0%,
        rgba(255, 255, 255, 0) 100%
      ),
      #16100d;
    box-shadow: 0px 0px 0px 1px rgba(241, 180, 155, 0.12),
      0px 1px 2px 0px rgba(241, 180, 155, 0.2);
  }
</style>
